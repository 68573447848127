.Input{
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 15px;
}

.Label{
    font-weight: bold;
    display: block;
    margin-bottom: 8px;
}

.Invalid{
    border: 1px solid orange;
}

.ErrorMessage{
    color: orange;
    margin: 5px 0;
    font-size: 10pt;
    animation: errorMessage 0.5s ease-out forwards;
}


@keyframes errorMessage{
    0%{
        opacity: 0;
        transform: translateY(-100%);
    }
    100%{
        opacity: 1;
        transform: translateY(0);
    }
}
