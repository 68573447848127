.SideHeader{
    background-color: seagreen;
    padding: 5px;
    writing-mode: vertical-rl;
    text-orientation: upright;
    text-align: center;
    min-height: 450px;
    margin-bottom: 15px;
    animation: SideHeaderAnimation 0.5s ease-in forwards;
}

.SideHeader h1{
    position: relative;
    animation: SideHeaderTextAnimation 5s linear infinite;
    text-transform: uppercase;
    margin: 0 auto;
}

@keyframes SideHeaderTextAnimation {
    0% {
        top: -70px;
      }
      50% {
        top: 20px;
      }
      100% {
        top: -70px;
      }
}

@keyframes SideHeaderAnimation {
  0% {
      transform: translateX(-250%);
    }
    100% {
      transform: translateX(0);
    }
}

@media (max-width: 775px) {
  .SideHeader{
    width:25px;
  }
  .SideHeader h1{
    font-size: 8pt;
  }
}