.DrawerToggle {
    margin-left: 10px;
    cursor: pointer;
}

.DrawerToggle i {
    font-size: 22pt;
}

@media (min-width: 803px) {
    .DrawerToggle {
        display: none;
    }
}