.Item{
    display: flex;
    flex-direction: row;
    margin: 15px 25px 0 0;
    float: left;
}

.Img{
    width: 80px;
    height: 80px;
    border-radius: 100%;
    background-color: rgb(212, 191, 191);
    opacity: 0.5;
}

.ItemBody {
    margin-left: 15px;
    width: 200px;
    height: 75px;
    background-color: rgb(212, 191, 191);
    opacity: 0.5;
    border-radius: 10px;
}

.Loader {
    animation: LoaderAnimation 0.6s ease-in-out infinite;
}

@keyframes LoaderAnimation {
    0%{
        opacity: 0.5;
    }
    100%{
        opacity: 0.8;
    }
}