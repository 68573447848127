.FacebookButton{
    width: 130px;
    padding: 8px;
    background-color: rgb(143, 74, 207);
    font-size: 10pt;
    font-weight: bold;
    border-radius: 25px;
    text-align: center;
}

.Link {
    color: inherit;
}

.Link:hover, .Link:active {
    color: inherit;
    text-decoration: none;
    border-radius: 25px;
}

.FacebookButton:hover{
    background-color: rgb(52, 110, 121);
}