.Item{
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    margin: 15px 25px 0 0;
    float: left;
}

.Item img{
    width: 80px;
    height: 80px;
    border-radius: 100%;
    cursor: pointer;
}

.Item img:hover{
    opacity: 0.8;
}

.ItemBody {
   margin-left: 15px;
}

@media (max-width: 371px){
    .ItemBody h4{
        font-size: 12pt;
    }
}