.Text{
    width: 100%;
    height: 300px;
    background-color: rgb(212, 191, 191);
    opacity: 0.5;
    animation: loaderAnimation 0.6s ease-in-out infinite;
}

.Img{
    width: 100%;
    height: 300px;
    background-color: rgb(212, 191, 191);
    opacity: 0.5;
    animation: loaderAnimation 0.6s ease-in-out infinite;
}


@keyframes loaderAnimation {
    0%{
        opacity: 0.5;
    }
    100%{
        opacity: 0.8;
    }
}