.Toolbar{
    height: 40px;
    width: 100%;
    position: relative;
    top: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 8px;
    box-sizing: border-box;
    z-index: 90;
    font-size: 12pt;
    /* border-bottom: 2px solid lightgrey; */
    margin-bottom: 15px;
    margin-top: 10px;
}

.Toolbar nav{
    height: 100%;

}

@media (max-width: 803px) {
    .DesktopOnly {
        display: none;
    }
}