
.ContactUs {
    padding: 15px;
}



.ContactUs input,
.ContactUs textarea{
    background-color:  #c5c5c5 ;
    color: rgb(22, 22, 22);
    border-color: transparent;
    border-radius: 10px;

}

.ContactUs input:focus,
.ContactUs textarea:focus{
    background-color: rgb(190, 171, 171);
    color: rgb(22, 22, 22);
    border-color: transparent;
}

.ContactImg{
    width: 250px;
    position: absolute;
    bottom: 0;
    right: 0;
    animation: ContactImgAnimation 0.5s ease-in-out forwards;
}

@keyframes ContactImgAnimation{
    0%{
        transform: translateX(100%);
    }

    100%{
        transform: translateX(0);
    }
}

.ContactUs a {
    color:lightskyblue;
}

.ContactUs a:hover {
    color:rgb(71, 184, 255);
    text-decoration: none;
}



@media (max-width: 775px) {
    .Right {
        margin-top: 25px;
    }
}

@media (min-width: 992px) {
    .Right {
        border-left: 3px solid lightgrey;
    }
}