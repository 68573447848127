
.Image{
    width: 200px;
    height: 200px;
}

.Image {
    float: left;
    margin: 0 30px 30px 0;
    box-shadow: 0 2px 3px rgb(48, 48, 48);
    background-color: rgb(212, 191, 191);
    opacity: 0.5;
    animation: LoaderAnimation 0.6s ease-in-out infinite;
}

@keyframes LoaderAnimation {
    0%{
        opacity: 0.5;
    }
    100%{
        opacity: 0.8;
    }
}