body{
    /* background-color: #2B343A; */
    /* box-shadow: 0 50vh 0 50vh #2B343A; */
    background-image: url(../../assets/images/bg1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100%;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow: auto;
    /* padding: 15px; */
    animation: LayoutAnim 0.8s ease-out forwards;
}


@keyframes LayoutAnim {
    0%{
        opacity: 0;
    }
    20%{
        opacity: 0.2;
    }
    40%{
        opacity: 0.4;
    }
    60%{
        opacity: 0.6;
    }
    80%{
        opacity: 0.8;
    }
    100%{
        opacity: 1;
    }

}

::-webkit-scrollbar {
    width: 5px;
  }
  
/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: red;
    border-radius: 10px;
}


.Content{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 10%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 80%;
    height: 80%;
    background: rgb(106,164,162);
    background: linear-gradient(135deg, rgba(106,164,162,1) 0%, rgba(13,97,25,1) 12%, rgba(102,127,168,1) 99%); 
    color: lightgrey;
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
    box-sizing: border-box;
    box-shadow: 10px 10px 10px 3px rgb(24, 24, 24);

    // animation: contentAnimation 0.5s ease-in forwards;
}

@keyframes contentAnimation {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(-50%);
    }
}



.Toolbar{
    float: right;
}



.Hanger{
    width: 8px;
    height: 90px;
    background: rgb(106,164,162);
    background: linear-gradient(223deg, rgba(106,164,162,1) 0%, rgba(42,226,68,1) 48%, rgba(101,128,167,1) 98%, rgba(102,127,168,1) 100%);     position: absolute;
    top: 0;
    box-shadow: 5px 5px 5px black;
    border: 1px solid rgb(22, 22, 22);
}

.HangerL {
    left: 25%;
}

.HangerR {
    right: 25%;
}

.Chain{
    width: 100px;
    position: absolute;
    top:0;
}

.ChainL{
    left: 25%;
}

.ChainR{
    right: 25%;
}

.Footer{
    color: lightgrey;
    text-align: center;
    position: fixed;
    bottom: 0;
    right: 0;
    margin-right: 20px;
    font-weight: bold;
}

.Languages {
    font-size: 10pt;
    text-align: center;
    background-color: rgb(62, 121, 33);
    padding: 2px;
    border-radius: 25px;
    margin: 0 auto;
    margin-bottom: 20px;
    width: 80px;
}

.Languages button {
    color: orange;
}

.Languages button:hover, .Languages button:focus {
    text-decoration: none;
    color: rgb(170, 170, 170);
    border-radius: 25px;
}

.Languages button.EN, .Languages button.FR {
    color: rgb(170, 170, 170);
    cursor: auto;
}

@media (max-width: 500px) {
    .Content {
        width: 90%;
        height: 90%;
        left: 5%;
    }
}


@media (max-width: 803px){
    .Toolbar {
        float: left;
        position: absolute;
        top: -75px;
    }

    .Logo div {
       text-align: center;
    }
}

@media (min-width: 775px) {
    .Languages {
        margin-top: 20px;
    }
}

