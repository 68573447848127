.Menu{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.MenuContent{
    margin-left: 25px;
    flex: 1;
}

.MenuBtns {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 15px;
}

.BtnMenu{
    padding: 5px;
    width: 100px;
    text-align: center;
    background-color: rgb(19, 89, 129);
    cursor: pointer;
    border-radius: 25px;
    box-shadow: 0 2px 3px rgb(37, 37, 37);
}

.BtnMenuAnimated {
    animation: btnMenuAnimation 1s ease-in-out infinite;
}

@keyframes btnMenuAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.4;
    }
    75% {
        opacity: 0.7;
    }
    100% {
        opacity: 1;
    }
}

.BtnMenu:hover {
    background-color: rgb(214, 151, 96);
    box-shadow: none;
}

.MenuPlaceHolder{
    width: 100%;
    margin-top: 100px;
    text-align: center;
    align-items: center;
}

@media (max-width: 700px) {
    .MenuBtns {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
    }   
    .BtnMenu {
        margin-bottom: 10px;
        width: 100%;
    } 
}

@media (max-width: 340px){
    .BtnMenu{
        width: 130px;
        margin: 0 auto;
        margin-bottom: 10px;

    }
}