.Location{
    display: flex;
    flex-direction: row;
}

.MapLoader{
    width: 100%;
    height: 450px;
    background-color: lightpink;
    animation: MapLoaderAnimation 0.8s infinite;
}

@keyframes MapLoaderAnimation {
    0% {
        opacity: 0;
    }
    
    100% {
        opacity: 0.8;
    }
}

.InfoBox{
    width: 100%;
    padding: 15px;
    background-color: rgb(110, 150, 126);
    box-shadow: 0 2px 3px rgb(48, 48, 48);
}

.AddressBoxLoader{
    width: 100%;
    height: 85px;
    background-color: rgb(212, 191, 191);
    opacity: 0.5;
    box-shadow: 0 2px 3px rgb(48, 48, 48);
    animation: BoxLoaderAnimation 0.6s ease-in-out infinite;
}

.oTimesBoxLoader{
    width: 100%;
    height: 167px;
    background-color: rgb(212, 191, 191);
    opacity: 0.5;
    box-shadow: 0 2px 3px rgb(48, 48, 48);
    animation: BoxLoaderAnimation 0.6s ease-in-out infinite;
}


@keyframes BoxLoaderAnimation {
    0%{
        opacity: 0.5;
    }
    100%{
        opacity: 0.8;
    }
}

.SideHeader {
    flex: 1;
}

.SideContent{
    display: flex;
    flex-direction: row;
    flex: 10;
}

.AddressSide {
    flex: 1;
}

.AddressSide hr {
    border-color: lightgrey;
}

.MapSide {
    flex: 2;
    margin-left: 50px;
}

.IFrame {
    width: 100%;
}

@media (max-width: 992px) {
    .SideContent {
        flex-direction: column;
        margin-left: 25px;
    }
    .MapSide {
        margin-left: 0;
        margin-top: 20px;
    }
}

@media (max-width: 500px) {
    .IFrame {
        height: 300px;
    }
}