.Home{
    padding: 40px;
}

.Home h1{
    font-size: 3.5em;
}

.SocialLinks{
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

@media (max-width: 775px) {
    .Right {
        margin-top: 25px;
    }
    .Home{
        padding: 15px;
    }
}