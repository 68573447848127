.Gallery{
    display: flex;
    flex-direction: row;
}

.Images {
    margin-left: 30px;
}

.Image, .Image img{
    width: 200px;
    height: 200px;
}

.Image {
    float: left;
    margin: 0 30px 30px 0;
    box-shadow: 0 2px 3px rgb(48, 48, 48);
    cursor: pointer;
}

.Image:hover {
    opacity: 0.7;
}