.MenuBtns {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 15px;
}

.BtnMenu{
    padding: 5px;
    width: 100px;
    text-align: center;
    background-color: rgb(19, 89, 129);
    border-radius: 25px;
    cursor: pointer;
    opacity: 0.5;
    animation: btnMenuAnimation 0.6s ease-in-out infinite;
}

@keyframes btnMenuAnimation {
    0% {
        opacity: 0.5;
    }
    100% {
        opacity: 0.8;
    }
}